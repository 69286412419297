// Step6.js

import React, { useState } from 'react';
import { API_URL } from '../Constants';
import axios from 'axios';
import '../global.css';

function Step6({ draftId }) {
  const [feedback, setFeedback] = useState('');
  const FEEDBACKURL = 'https://script.google.com/macros/s/AKfycbw2diVm2ikradZ_r0PRo15JqmLZ6PkFYC0VyG8Wb-co4Y1c5KZYt11SNDAZP_-tN2bTuA/exec';
  
  const handleDownload = async () => {
    try {
      const response = await axios({
        url: API_URL + 'download-word/' + draftId,
        method: 'GET',
        responseType: 'blob',
      });
  
      const blob = response.data;
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'LexIntel_draft' + draftId + '.docx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // feedback to Google Docs here
      const formData = {
        Body: feedback,
        DraftID: draftId,
      };
      fetch(FEEDBACKURL, {
        method: 'POST',
        mode: 'no-cors', // Important to avoid CORS errors
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: new URLSearchParams(formData)
      })
      .then(response2 => console.log(response2))
      .catch(error => console.error('Error:', error));

    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  return (
    <div>
      <div className='block' style={{ marginBottom: '8px' }}><h3 className="title is-4">Review and Finalize</h3></div>
      <div className='block'>Please review the draft and download.</div>
      <div className='block'>
        <label className='label' htmlFor="introBody">Before you download, please tell us about your experience.</label>
        <textarea className="textarea" id="introBody" name="introBody" rows="4" cols="40" value={feedback} onChange={(e) => setFeedback(e.target.value)}></textarea>
      </div >
      <div className='has-text-right'>
        <button 
          className="button is-success" 
          onClick={handleDownload} disabled={!feedback.trim()} 
          title={!feedback.trim() ? "Please add feedback before downloading" : "Click to download"}>
          Download as word document
        </button>
      </div>
    </div>
  );
}

export default Step6;