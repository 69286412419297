import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';

const getSession = async () => {
    try {
        const session = await fetchAuthSession();
        return { idToken: session.tokens.idToken, accessToken: session.tokens.accessToken };
    } catch (err) {
        return false;
    }
};

export const setHeaders = async () => {
    try {
        const tokenParts = await getSession();
        if (tokenParts) {
            const token = tokenParts.accessToken;
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    } catch (error) {
        console.error('Error fetching token:', error);
    }
};

export const ensureHeaders = async () => {
    if (!axios.defaults.headers['Authorization']){
        await setHeaders();
    }
};