// Step4.js

import React, { useState } from 'react';
import '../global.css';

function Step4({onSubmit, advSt, intro}) {
  const [isChecked, setIsChecked] = useState(true);

  const handleToggle = () => {
      setIsChecked(!isChecked);
  };


  return (
  <div>
    <form onSubmit={onSubmit}>
      <h3 className="title is-4">Introduction</h3>
      <div className='block' style={{ marginBottom:'4px' }}>
        <div class="field">
          <input id="switchRoundedInfo" type="checkbox" name="wants_intro" className="switch is-rounded is-info is-medium" checked={isChecked} onChange={handleToggle}></input>
          <label for="switchRoundedInfo">Include Introduction</label>
        </div>
        {/* <select className="dropdown is-active" id="useIntro" name="useIntro">
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select> */}
      </div>
      <div>
        <label className='label' htmlFor="introBody">Suggested Introduction:</label>
        <textarea className="textarea" id="introBody" name="introBody" defaultValue={intro} rows="8" cols="50"></textarea>
      </div>
      <div className="has-text-right" style={{ marginTop:'24px'}}>
        <button className="button is-normal is-success" type="submit">Next: Review and finalize</button>
      </div>
    </form>
    {/* <div className="has-text-right">
        <button className="button is-normal is-success" type="button" onClick={advSt}>Next Step</button>
      </div> */}
  </div>
  );
}

export default Step4;