// ElementChecker.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../Constants.js';
import Step0 from '../answer/Step0.js';
import Step1 from './Step1.js';
import Step3 from '../rfp/Step3.js';

import ModalWithProgressBar from '../ModalWithProgressBar.js';
import { ensureHeaders } from '../auth/useSetHeaders';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown } from '@mdi/js';
import '../global.css';


function ElementChecker() {
  const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
  const [key, setKey] = useState(0);
  const [appState, setAppState] = useState(0); // Initial state
  const [taskId, setTaskId] = useState(null);  // For async
  const [selectedFile, setSelectedFile] = useState(null);
  const [elData, setElData] = useState({'first COA': [['Ownership of a valid copyright', 'The complaint adequately pleads this element. Plaintiff asserts that he is the sole exclusive owner of all right, title, and interest in the work and copyright to the Screenplay.', 'While the complaint states that the Screenplay was submitted for registration, it does not provide confirmation of the registration being granted.']], 
    'second COA': [['Volitional conduct by the defendant ', 'The complaint provides ample factual basis to show volitional conduct by Gawker. It includes detailed allegations that Gawker knowingly downloaded the unauthorized Screenplay.', 'The defense could assert that Gawkers posting of the links was an act of journalistic reporting rather than volitional conduct aimed at infringing the copyright. ']]});

  // modal 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  let { draftId } = useParams();

  const advSt = () => {
    setAppState(appState + 1);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (selectedFile) {
      formData.append('file', selectedFile);
      console.log(formData);
    }
    if (!selectedFile && appState === 0){
      alert("Please select a file before submitting!");
      return
    }

    // formData.appState = appState;
    setModaIsActive(true);
    setModalProgress(5);
    formData.append('appState', appState)
    formData.append('draftId', parseInt(draftId))

    try {
      await ensureHeaders();
      const response = await axios.post(API_URL + 'elementchecker', formData);
      // Axios automatically handles the conversion of the response to JSON
      const result = response.data;
      if (response.status === 200) { 
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Form submission failed', error);
    }
  };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready")
        console.log(response.data);

        setModalProgress(100);
        // update the file to display
        setFileName(response.data.value.file_name);
        setKey(prevKey => prevKey + 1);

        if ('el_data' in response.data.value){
          console.log("setting el data");

          setElData(response.data.value.el_data);
          console.log(response.data.value.el_data);
        }
        advSt();

        setModaIsActive(false);
        
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert("There was an error, please contact customer support.");
    }
  };
    
  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = appState === 0 ? 7 : 5;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);


    // Array to hold tab data
    const tabs = [
        { icon: mdiTrayArrowUp, text: 'Upload' },
        { icon: mdiFileDocumentEditOutline, text: 'Finish' },
        { icon: mdiTrayArrowDown, text: 'Finish' }
    ];

  return (
    <div className="App">
        <div className="split left">
            <div className="dropdown-container" style={{margin: '20px 32px'}}>
            <div className="tabs is-toggle is-progress">
                <ul>
                    {tabs.map((tab, index) => (
                        <li key={index} className={index === appState ? 'is-active' : ''}>
                        <a style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                            <span className="icon is-small">
                              <Icon path={tab.icon} size={1} />
                            </span>
                            <span>{tab.text}</span>
                        </a>
                        </li>
                    ))}
                </ul>
            </div>
                <div>
                    {appState === 0 && <Step0 onSubmit={handleSubmit} onChange={handleFileChange} fileName={selectedFile}/>}
                    {appState === 1 && <Step1 onSubmit={handleSubmit} inputObj={elData} />}
                    {appState === 2 && <Step3 draftId={draftId} />}
                </div>
            </div> 
            <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
        </div>
        <div className="split right">
            <embed key={key} src={fileName} width={"100%"} height={2000} />
        </div>
    </div>
  );
}

export default ElementChecker;
