// ModalWithProgressBar.js

import React from 'react';
import './global.css';

const ModalWithProgressBar = ({ isActive, progress }) => {
  return (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="block" style={{ paddingBottom: '16px', textAlign: 'center'}}>
          <p className="title is-5">Analyzing and writing</p>
        </div>
        <progress className="progress is-small is-info" value={progress} max="100">{progress}%</progress>
      </div>
      <button className="modal-close is-large" aria-label="close"></button>
    </div>
  );
};

export default ModalWithProgressBar;