import React, { useState, useEffect } from 'react';
import { API_URL } from '../Constants';
import axios from 'axios';

function Profile() {
  const [contactInfo, setContactInfo] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {'contactInfo': contactInfo};

    try {
      const response = await axios.post(API_URL + 'updateprofile', userData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
    
      setMessage('Profile Updated Successfully');
      console.log(response.data); // Process result
    
    } catch (error) {
      if (error.response) {
        console.error('Failed to update info', error.response.data);
        setMessage('Failed to update info: ' + error.response.data['msg']);
      } else if (error.request) {
        console.error('Failed to update info', error.request);
      } else {
        console.error('Error:', error.message);
      }
      setMessage('Error updating info');
    }
  };

  useEffect(() => {

    const fetchProfile = async () => {
      try {
        const response = await axios.post(API_URL + 'getprofile');
        if ('contact_info' in response.data){
            setContactInfo(response.data.contact_info || "");
          } 
      } catch (err) {
        setMessage('Failed to fetch data');
        console.error(err); 
      }
    };

    fetchProfile();
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <h2 className="title">Profile</h2>
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label" htmlFor="email">Contact Info:</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    id="email"
                    name="email"
                    placeholder="Enter Attorneys contact info here."
                    value={contactInfo}
                    onChange={(e) => setContactInfo(e.target.value)}
                    rows="8"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button className="button is-success" type="submit">Update</button>
                </div>
              </div>
            </form>
            {message && <p className="help is-danger">{message}</p>}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;