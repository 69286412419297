// Step0.js

import React from 'react';
import Icon from '@mdi/react';
import { mdiTrayArrowUp } from '@mdi/js';
import '../global.css';

function Step0({onSubmit, onChange, fileName}) {

  return (
    <div>
      <h3 className="title is-4" style={{ marginBottom: '8px'}}>Upload a Complaint</h3>
      <form onSubmit={onSubmit}>
        <div className="file block has-name upload-container" style={{ gap: '6px' }}>
          <p>Upload a complaint to continue</p>
          <label className="file-label">
            <input className="file-input" type="file" name="fileUpload" id="fileUpload" onChange={onChange}/>
            <span className="file-cta">
              <span className='icon' style={{ marginRight:'0.25em' }}>
                <Icon path={mdiTrayArrowUp} size={0.9} />
              </span>
              <span className="file-label">{fileName ? fileName.name : "Select a file to upload"}</span>
            </span>
          </label>
        </div>
        <div className="has-text-right">
          <button className="button is-link" type="submit">Upload complaint and continue</button>
        </div>
      </form>
    </div>
  );
}

export default Step0;