// src/amplify-confg.js

const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: "us-east-1_EUWrFCow8",
      userPoolClientId: "5cgjaf4p967ultmok8oebjst9b",
      identityPoolId: "us-east-1:595d5b44-fd43-491c-b5f9-2d02ba0706bf",
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
      mfaConfiguration: "ON", // Enable MFA
      mfaTypes: ["TOTP"], // Define types of MFA
      totpEnabled: true, // Enable Time-based One-Time Password (TOTP)
    },
  },
};

export default awsConfig;
