// Step4.js

import React from 'react';
import '../global.css';

function Step4({onSubmit, advSt, intro}) {
  return (
  <div>
    <form onSubmit={onSubmit}>
      <div className='block' style={{ marginBottom: '8px' }}><h3 className="title is-4">Introduction</h3></div>
        <div style={{display: 'flex', flexDirection:'column', gap: '16px'}}>
          <div>
            <label className='label' htmlFor="introBody">Suggested Introduction:</label>
            <textarea className="textarea" id="introBody" name="introBody" defaultValue={intro} rows="8" cols="50"></textarea>
          </div>
          <div className='block'>
            <label className='label'>Do you want to include this introduction in your complaint?</label>
            <select className="select" id="useIntro" name="useIntro" style={{ maxWidth:'100px'}}>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="has-text-right">
            <button className="button is-normal is-success" type="submit">Update draft and continue</button>
          </div>
        </div>
    </form>
    {/* <div className="has-text-right">
        <button className="button is-normal is-success" type="button" onClick={advSt}>Next Step</button>
      </div> */}
  </div>
  );
}

export default Step4;