// ObjectionsDrafter.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../Constants.js';
import Step0 from './Step0.js';
import Step2 from './Step2.js';
import Step1 from './Step1.js';
import Step3 from '../rfp/Step3.js'; 
import ModalWithProgressBar from '../ModalWithProgressBar.js';
import { ensureHeaders } from '../auth/useSetHeaders';
import Icon from '@mdi/react';
import { mdiFileDocumentEditOutline, mdiTrayArrowUp, mdiTrayArrowDown } from '@mdi/js';
import '../global.css';

function ObjectionsDrafter() {
  const [fileName, setFileName] = useState(process.env.PUBLIC_URL + '/CV-126.pdf');
  const [key, setKey] = useState(0);
  const [appState, setAppState] = useState(0); // Initial state
  const [taskId, setTaskId] = useState(null);  // For async
  const [selectedFile, setSelectedFile] = useState(null);
  const [definition, setDefinition] = useState("A proposed definition section will appear here after RFPs have been selected.");
  const [objections, setObjections] = useState([["(Breach of Contract)", "An explanation why this is a good cause will appear here"], 
  ["(California Penal Code 502)", "An explanation why this is a good cause will appear here"]]);
  const [instObjections, setInstObjections] = useState([["(Breach of Contract)", "An explanation why this is a good cause will appear here"], 
    ["(California Penal Code 502)", "An explanation why this is a good cause will appear here"]]);
  const [instructions, setInstructions] = useState(["Proposed instructions will appear here."]);
  const [intro, setIntro] = useState("Plaintiff hereby objects and responds to the First Set of Requests for Production of Documents (Nos. 1-146) served by Defendants.");
  const [general, setGeneral] = useState("");
  // modal 
  const [modalIsActive, setModaIsActive] = useState(false);
  const [modalProgress, setModalProgress] = useState(0);

  let { draftId } = useParams();

  const advSt = () => {
    setAppState(appState + 1);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    if (selectedFile) {
      formData.append('file', selectedFile);
      console.log(formData);
    }
    if (!selectedFile && appState === 0){
      alert("Please select a file before submitting!");
      return
    }

    // formData.appState = appState;
    setModaIsActive(true);
    setModalProgress(5);
    formData.append('appState', appState)
    formData.append('draftId', parseInt(draftId))

    try {
      await ensureHeaders();
      const response = await axios.post(API_URL + 'rfpresponsedrafter', formData);
      // Axios automatically handles the conversion of the response to JSON
      const result = response.data;
      if (response.status === 200) { 
        setTaskId(result.result_id);
        console.log('Task submitted successfully');
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Form submission failed', error);
    }
  };

  // Function to check the task status
  const checkTaskStatus = async () => {
    try {
      console.log("checking the status");
      const response = await axios.get(`${API_URL}result/${taskId}`);
      if (response.data.ready) {
        setTaskId(null); // Reset taskId
        console.log("the data is ready")
        console.log(response.data);

        // this could be streamlined with a map
        if ('instructions' in response.data.value){
          setInstructions(response.data.value.instructions);
        }
        if ('definition_objections' in response.data.value){
          setObjections(response.data.value.definition_objections);
        }
        if ('instruction_objections' in response.data.value){
            setInstObjections(response.data.value.instruction_objections);
          }
        if ('definition' in response.data.value){
          setDefinition(response.data.value.definition);
        }
        if ('intro' in response.data.value){
          setIntro(response.data.value.intro);
        }
        if ('general' in response.data.value){
          setGeneral(response.data.value.general);
        }

        setModalProgress(100);
        // update the file to display
        setFileName(response.data.value.file_name);
        setKey(prevKey => prevKey + 1);

        advSt();

        setModaIsActive(false);
        
      }
    } catch (error) {
      console.error('Error checking task status:', error);
      setTaskId(null);
      setModaIsActive(false);
      alert("There was an error, please contact customer support.");
    }
  };
    
  // Start polling when taskId is set
  useEffect(() => {
    let intervalId;
    if (taskId) {
      intervalId = setInterval(() => {
        checkTaskStatus();
        let delta = appState === 0 ? 7 : 5;
        setModalProgress(modalProgress => modalProgress + delta);
      }, 5000); // Poll every 5000 milliseconds (5 seconds)
    }
    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [taskId]);

  // This is just for debug, delete
  useEffect(() => {
    console.log("instObjections updated:", instObjections);
    console.log("definitions ojbections: ", objections);
  }, [instObjections]);  // This effect will run whenever elData is updated


    // Array to hold tab data
    const tabs = [
        { icon: mdiTrayArrowUp, text: 'Upload' },
        { icon: mdiFileDocumentEditOutline, text: 'Supporting Sections' },
        { icon: mdiFileDocumentEditOutline, text: 'Objections' },
        { icon: mdiTrayArrowDown, text: 'Finish' }
    ];

  return (
    <div className="App">
        <div className="split left">
            <div className="dropdown-container" style={{margin: '20px 32px'}}>
            <div className="tabs is-toggle is-progress">
                <ul>
                    {tabs.map((tab, index) => (
                        <li key={index} className={index === appState ? 'is-active' : ''}>
                        <a style={{ padding: '8px', display: 'inline-flex', alignItems:'center' }}>
                            <span className="icon is-small">
                            <Icon path={tab.icon} size={1} />
                            </span>
                            <span>{tab.text}</span>
                        </a>
                        </li>
                    ))}
                </ul>
            </div>
                <div>
                    {appState === 0 && <Step0 onSubmit={handleSubmit} onChange={handleFileChange} fileName={selectedFile}/>}
                    {appState === 1 && <Step1 onSubmit={handleSubmit} instObjections={instObjections} initialCoas={objections}/>}
                    {appState === 2 && <Step2 onSubmit={handleSubmit} general={general} intro={intro} defs={definition} instructions={instructions}/>}
                    {appState === 3 && <Step3 draftId={draftId} />}
                </div>
            </div> 
            <ModalWithProgressBar isActive={modalIsActive} progress={modalProgress} />
        </div>
        <div className="split right">
            <embed key={key} src={fileName} width={"100%"} height={2000} />
        </div>
    </div>
  );
}

export default ObjectionsDrafter;