// Step0.js

import React, { useState, useRef } from 'react';
import CourtSelector from './CourtSelector';
import Icon from '@mdi/react';
import { mdiTrayArrowUp } from '@mdi/js';
import '../global.css';


function Step0({onSubmit, advSt, onChange, fileName}) {
  const [hiddenValue, setHiddenValue] = useState('');

  /* minimum stuff to check inputs: plaintiff, defendant,  */
  const plaintiffRef = useRef();
  const defendantRef = useRef();
  const faRef = useRef();
  const fileRef = useRef();

  const updateHiddenValue = (newValue) => {
    setHiddenValue(newValue);
  };

  const validateInputs = async (e) => {
    e.preventDefault(); 
    
    if (!plaintiffRef || !plaintiffRef.current || !plaintiffRef.current.value.trim()) {
      alert("Plaintiff is required");
      return;
    }
    
    if (!defendantRef || !defendantRef.current || !defendantRef.current.value.trim()) {
        alert("Defendant is required");
        return; 
    }

    // Check if faRef or fileRef are empty
    const faValid = faRef && faRef.current && faRef.current.value.trim();
    const fileValid = fileRef && fileRef.current && fileRef.current.value
    if (!faValid && !fileValid) {
      console.log("faValid: ", faValid);      
      alert("Factual allegations in the text box or file are required");
      return; 
    }

    // If all validations pass, proceed with form submission
    onSubmit(e);
  }

  return (
    <div>
      <div className='block' style={{ marginBottom: '8px' }}><h3 className="title is-4">Basic Facts</h3></div>

      <form onSubmit={validateInputs} style={{display: 'flex', flexDirection:'column', gap: '16px'}}>
        <div>
          <label className='label' htmlFor="plaintiff">Plaintiff:</label>
          <input className="input" type="text" id="plaintiff" name="plaintiff" ref={plaintiffRef}/>
        </div>
        <div>
          <label className='label' htmlFor="plaintiffAddress">Plaintiff Residence/Headquarters:</label>
          <input className="input" type="text" id="plaintiffAddress" name="plaintiffAddress" />
        </div>
        <div>
          <label className='label' htmlFor="defendant">Defendant:</label>
          <input className="input" type="text" id="defendant" name="defendant" ref={defendantRef}/>
        </div>
        <div>
          <label className='label' htmlFor="defendantAddress">Defendant Residence/Headquarters:</label>
          <input className="input" type="text" id="defendantAddress" name="defendantAddress" />
        </div>
        <div>
          <CourtSelector venuSetter={updateHiddenValue}/>
          <input type="hidden" name="venue" value={hiddenValue} />
        </div>
        <div>
          <label className='label' htmlFor="factualAllegations">Factual Allegations:</label>
          <textarea className="textarea" id="factualAllegations" name="factualAllegations" rows="8" cols="50" ref={faRef}></textarea>
        </div>
        <div className='upload-container' style={{ gap: '6px' }}>
          <div>
            <p>If your factual allegations are in a file you can attach it here.  
            </p>
          </div>

          <div className="file block has-name">
            <label className="file-label">
              <input className="file-input" type="file" name="fileUpload" id="fileUpload" onChange={onChange} ref={fileRef}/>
              <span className="file-cta">
                <span className='icon' style={{ marginRight:'0.25em' }}>
                  <Icon path={mdiTrayArrowUp} size={0.9} />
                </span>
                <span className="file-label">{fileName ? fileName.name : "Select a file to upload"}</span>
              </span>
            </label>
          </div>
        </div>
        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Draft complaint</button>
        </div>
      </form>
    </div>
  );
}

export default Step0;