// Step2.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../global.css';

function Step2({onSubmit, advSt, initialAds}) {
  const [textAreas, setTextAreas] = useState(initialAds); // Start with passed in values

  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  return (
    <div>
      <h2 className='title is-4'>Suggested Affirmative Defenses</h2>
      <form onSubmit={onSubmit}>
      <div className='block'>
          {textAreas.map((text, index) => (
            <div key={index} className="box is-draft">
              <h4 className="title is-5">Affirmative Defense {index + 1}</h4>
              <textarea className="textarea" id={`ad${index}`} name={`ad${index}`}value={text[0]} readOnly={true} rows="2" style={{ marginBottom: '24px'}}/>
              <textarea className="textarea" value={text[1]} rows="4" readOnly={true} />

              <div className="field">
                <label htmlFor={`ignore-ad${index}`}>
                <input className="is-checkradio" type="checkbox" id={`ignore-ad${index}`} name={`ignore-ad${index}`}/>
                Ignore this Affirmative Defense
                </label>
              </div>
            </div>
          ))}
          <button type="button" className="button is-small is-secondary" onClick={addTextArea}>
            <span className='icon'>
              <Icon path={mdiPlus} size={1} />
            </span>
            <span>
              Add Affirmative Defense
            </span>
          </button>
        </div>
        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next: Counterclaim</button>
        </div>
      </form>
      {/* <div className="has-text-right">
        <button className="button is-normal is-success" type="button" onClick={advSt}>Next Step: Counter Claims</button>
      </div> */}
    </div>
  );
}

export default Step2;

