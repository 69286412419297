// Step1.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../global.css';


function Step1({onSubmit, instObjections, initialCoas}) {
  const [textAreas, setTextAreas] = useState(initialCoas);
  const [textAreasI, setTextAreasI] = useState(instObjections);

  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  function addTextAreaI() {
    setTextAreasI([...textAreasI, '']); // Add a new empty textarea
  }

  return (
    <div>
      <div className='block'><h3 className="title is-4" style={{ marginBottom: '8px' }}>Definitions Objections and Responses</h3></div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          {textAreas.map((text, index) => (
            <div key={index} className="box is-draft">
              <h3 className="title is-5">Objection No. {index + 1}</h3>
              <textarea className="textarea" id={`coa${index}`} name={`coa${index}`} defaultValue={text.objection} rows="4" style={{ marginBottom: '24px'}} />

              <div className="field">
                <label htmlFor={`ignore-coa${index}`}>
                  <input className="is-checkradio" type="checkbox" id={`ignore-coa${index}`} name={`ignore-coa${index}`} />
                  Ignore this Objection
                </label>
              </div>
            </div>
          ))}
        </div>
        
        <div className='block'>
          <button type="button" className="button is-small is-secondary" onClick={addTextArea}>
            <span className='icon'>
              <Icon path={mdiPlus} size={1} />
            </span>
            <span>
              Add Objection
            </span>
          </button>
        </div>

        <div className='block'><h3 className="title is-4" style={{ marginTop:'80px', marginBottom: '8px' }}>Instructions Objections and Responses</h3></div>
        <div className='block'>
          {textAreasI.map((text, index) => (
            <div key={index} className="box is-draft">
              <h3 className="title is-5">Objection No. {index + 1}</h3>
              <textarea className="textarea" id={`coa${index}`} name={`coa${index}`} defaultValue={text.objection} rows="4" style={{ marginBottom: '24px'}} />

              <div className="field">
                <label htmlFor={`ignore-coa${index}`}>
                  <input className="is-checkradio" type="checkbox" id={`ignore-coa${index}`} name={`ignore-coa${index}`} />
                  Ignore this Objection
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className='block'>
          <button type="button" className="button is-small is-secondary" onClick={addTextAreaI}>
            <span className='icon'>
              <Icon path={mdiPlus} size={1} />
            </span>
            <span>
              Add Objection
            </span>
          </button>
        </div>
        <div className='block has-text-right'>
          <button className="button is-normal is-success" type="submit">Update Draft</button>
        </div>
      </form>
    </div>
  );
}

export default Step1;