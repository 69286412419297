// Step1.js

import React, { useState } from 'react';


function Step1({onSubmit, inputObj}) {
  const [selectedValues, setSelectedValues] = useState({});

  const handleSelectChange = (id, value) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: value
    }));
  };


  return (
    <div>
      <div class="block">
        <h1 className="title is-4" style={{ marginBottom: '8px' }}>Complaint Analyzer</h1>
      </div>
      <form onSubmit={onSubmit}>
        <div>
          {Object.entries(inputObj).map(([key, value]) => (
            <div key={key}>
              <h3 className="title is-5" style={{marginBottom: "8px" }}>{key}</h3>
              <ul>
                {value.map((item, index) => (
                  <div style={{marginBottom: "28px" }} key={index}>
                    <b className='body' style={{fontWeight: "400" }}>{item[2]}</b> {/* Element */}
                    {item[3]}        {/* Analysis */}
                    <b className='body' style={{fontWeight: "400" }}>{item[4]}</b> {/* Defense section */}
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Next Step: Download</button>
        </div>
      </form>
    </div>
  );
}

export default Step1;
