import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from './Constants';
import { Link } from 'react-router-dom';
import ModalNewMatter from './ModalNewMatter';
import ModalNewDraft from './ModalNewDraft';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import './global.css';
import { ensureHeaders } from './auth/useSetHeaders';


function LoggedInLanding() {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [drafts, setDrafts] = useState([]);
  const [refreshDrafts, setRefreshDrafts] = useState(false);
  const [message, setMessage] = useState('\n');
  const [modalIsActive, setModalIsActive] = useState(false);
  const [modalDraftIsActive, setModalDraftIsActive] = useState(false);

  // Fetch projects
  useEffect(() => {
    fetchProjects();
  }, []);

  function getPath(draftType, draftId) {
    switch (draftType) {
      case 0:
        return `/ComplaintDrafter/${draftId}`;
      case 1:
        return `/AnswerDrafter/${draftId}`;
      case 2:
        return `/RfpDrafter/${draftId}`;
      case 3:
        return `/RogDrafter/${draftId}`;
      case 4:
        return `/RfaDrafter/${draftId}`;
      case 5:
        return `/ObjectionsDrafter/${draftId}`;
      case 6:
        return `/ElementChecker/${draftId}`;
      default:
        return '/NotFound'; // Fallback for unknown types
    }
  }

  function getDraftLabel(draftType) {
    switch (draftType) {
      case 0:
        return `Complaint`;
      case 1:
        return `Answer`;
      case 2:
        return `RFP`;
      case 3:
        return `ROGs`;
      case 4:
        return `RFAs`;
      case 5:
        return `Objections to RFPs`;
      case 6:
        return `Check Elements`;
      default:
        return 'NotFound'; // Fallback for unknown types
    }
  }

  useEffect(() => {
    if (selectedProject) {
      fetchDrafts();
    }
  }, [selectedProject]);

  const fetchProjects = async () => {
    await ensureHeaders();
    try {
      const response = await axios.post(API_URL + 'list-projects');
      setProjects(response.data.projects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchDrafts = async () => {
    await ensureHeaders();
    try {
      const response = await axios.post(API_URL + 'list-drafts', {
        'proj-id': selectedProject
      });
      setDrafts(response.data.drafts);
    } catch (error) {
      console.error('Error fetching drafts:', error);
    }
  };

  // Fetch drafts for a selected project.
  useEffect(() => {

    fetchDrafts();
  }, [selectedProject, refreshDrafts]);




  const deleteRow = (draftId) => {
    axios.post(API_URL + 'deletedraft', { draftId: draftId })
      .then(response => {
        setRefreshDrafts(prev => !prev);
      })
      .catch(error => {
        console.error('Error deleting row:', error);
      });
  };

  const deleteProject = (projectId) => {
    if (window.confirm('Are you sure you want to delete this matter?  All associated drafts will be deleted as well.')) {
      if (selectedProject == projectId) {
        console.log("deleted the current proj, setting null");
        setSelectedProject(null);
      }
      axios.post(API_URL + 'deleteproject', { projId: projectId })
        .then(response => {
          fetchProjects();
          setSelectedProject(null);
        })
        .catch(error => {
          console.error('Error deleting matter:', error);
          setMessage('Error deleting user');
        });
    };
  };

  return (
    <div className="container" style={{ maxWidth: '1200px', paddingTop: '40px' }}>
      <div className="columns is-centered" style={{ gap: '24px' }}>
        <div className="column is-half" style={{ padding:'20px' }}>
          <div className="block">
            {message && <p className="help is-danger">{message}</p>}
            <h2 className="title is-4" style={{ marginBottom: '12px' }}>Matters</h2>
            <table className="table is-hoverable is-fullwidth" style={{ borderBottom: '2px solid rgb(219,219,219)' }}>
              <thead>
                <tr>
                  <th>Matter name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {projects.length > 0 ? (
                  projects.map((project) => (
                    <tr key={project[1]} onClick={() => setSelectedProject(project[1])} className={selectedProject === project[1] ? 'is-selected' : ''}>
                      <td>{project[0]}</td>
                      <td style={{ width: '40px' }}>
                        <button className="button is-small" style={{ width: '40px', borderRadius: '6px', borderColor: 'rgba(0,0,0,0)', backgroundColor: 'rgba(0,0,0,0)'}} onClick={() => deleteProject(project[1])}>
                          <span className="icon is-danger is-small">
                            <Icon path={mdiTrashCanOutline} size={4} />
                          </span>
                        </button>
                      </td>
                    </tr>
                  ))
                ):(
                  <tr className='no-hover'>
                      <td colSpan="3" style={{ textAlign: 'center' }}>
                        <div style={{ padding: '40px' }}>
                          <img 
                            src="document-illustration.svg"
                            alt="Document illustration"
                            style={{ width: '180px', marginBottom: '12px' }}
                          />
                          <p className='title is-5' style={{ marginBottom:'8px' }}>No matters created.</p>
                          <p>Create a new matter to get started.</p>
                        </div>
                      </td>
                    </tr>
                )}
              </tbody>
            </table>
            <button className="button is-primary" onClick={() => setModalIsActive(!modalIsActive)}>
              Create New Matter
            </button>
          </div>
        </div>
        <div className="column is-half" style={{ padding:'20px' }}>
            <div className="block">
              <h2 className="title is-4" style={{ marginBottom: '12px' }}>Drafts</h2>
              <table className="table is-hoverable is-fullwidth" style={{ borderBottom: '2px solid rgb(219,219,219)' }}>
                <thead>
                  <tr>
                    <th>Draft name</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {drafts.length > 0 ? (
                    drafts.map((draft) => (
                      <tr key={draft[0]}>
                        <td>
                          <Link to={{ pathname: getPath(draft[3], draft[0]) }}> {draft[1]}</Link>
                        </td>
                        <td>{getDraftLabel(draft[3])}</td>
                        <td style={{ width: '40px' }}>
                          <button className="button is-small" style={{ width: '40px', borderRadius: '6px', borderColor: 'rgba(0,0,0,0)', backgroundColor: 'rgba(0,0,0,0)'}} onClick={() => deleteRow(draft[0])}>
                            <span className="icon is-danger is-small">
                              <Icon path={mdiTrashCanOutline} size={4} />
                            </span>
                          </button>
                        </td>
                      </tr>
                    ))
                  ):(
                    <tr className='no-hover'>
                      <td colSpan="3" style={{ textAlign: 'center' }}>
                        <div style={{ padding: '40px' }}>
                          <img 
                            src="document-illustration.svg"
                            alt="Document illustration"
                            style={{ width: '180px', marginBottom: '12px' }}
                          />
                          <p className='title is-5' style={{ marginBottom:'8px' }}>No drafts created.</p>
                          <p>Please select a matter to view drafts or create a new draft.</p>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {selectedProject && (
              <button className="button is-primary" onClick={() => setModalDraftIsActive(!modalDraftIsActive)}>
                Create New Draft
              </button>
              )}
            </div>
        </div>  
      </div>
      <ModalNewMatter isActive={modalIsActive} stateSetter={setModalIsActive} refreshProjects={fetchProjects} />
      <ModalNewDraft isActive={modalDraftIsActive} stateSetter={setModalDraftIsActive} refreshDrafts={fetchDrafts} projectId={selectedProject} />
    </div>
  );
}

export default LoggedInLanding;
