// Step1.js

import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import '../global.css';


function Step1({onSubmit, advSt, initialCoas}) {
  const [textAreas, setTextAreas] = useState(initialCoas);

  function addTextArea() {
    setTextAreas([...textAreas, '']); // Add a new empty textarea
  }

  return (
    <div>
      <div className='block'><h3 className="title is-4" style={{ marginBottom: '8px' }}>Requests for Admission</h3></div>
        <form onSubmit={onSubmit}>
          <div className='block'>
            {textAreas.map((text, index) => (
              <div key={index} className="box is-draft">
                <h3 className="title is-5">Request for Admission No. {index + 1}</h3>
                <textarea className="textarea" id={`rfa${index}`} name={`rfa${index}`} defaultValue={text['Admission Request']} rows="2" style={{ marginBottom: '24px'}} />
                <textarea className="textarea" value={text['Strategic Rationale']} rows="4" readOnly={true} />

                <div className="field">
                  <label htmlFor={`ignore-coa${index}`}>
                    <input className="is-checkradio" type="checkbox" id={`ignore-rfa${index}`} name={`ignore-rfa${index}`} />
                    Ignore this RFA
                  </label>
                </div>
              </div>
            ))}
          </div>

          <div className='block'>
            <button type="button" className="button is-small is-secondary" onClick={addTextArea}>
              <span className='icon'>
                <Icon path={mdiPlus} size={1} />
              </span>
              <span>
                Add Request for Admission
              </span>
            </button>
          </div>
        
          <div className='block has-text-right'>
            <button className="button is-normal is-success" type="submit">Update Draft</button>
          </div>
        </form>
    </div>
  );
}

export default Step1;
