// Step2.js

import React from 'react';


function Step2({onSubmit, advSt, intro, defs, instructions}) {

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className='block'>
          <h3 className="title is-4" style={{ marginBottom: '8px' }}>Supporting Sections</h3>
        </div>
        <div className='block'>
          <label htmlFor="introBody" className="title is-5" style={{ marginTop: '10px', marginBottom: '8px' }}>Introduction</label>
          <textarea className="textarea" id="introBody" name="introBody" defaultValue={intro} rows="8" cols="50"></textarea>
        </div>
        <div className='block'>
          <label htmlFor="defsBody" className="title is-5" style={{ marginTop: '10px', marginBottom: '8px' }}>Definitions</label>
          <textarea className="textarea" id="defsBody" name="defsBody" defaultValue={defs} rows="8" cols="50"></textarea>
        </div>
        <div className='block'>
          <label htmlFor="instructionsBody" className="title is-5" style={{ marginTop: '10px', marginBottom: '8px' }}>Instructions</label>
          <textarea className="textarea" id="instructionsBody" name="instructionsBody" defaultValue={instructions} rows="8" cols="50"></textarea>
        </div>

        <div className="has-text-right">
          <button className="button is-normal is-success" type="submit">Update Draft</button>
        </div>
      </form>
      {/* <div className="has-text-right">
          <button className="button is-normal is-success" type="button" onClick={advSt}>Next Step: Download</button>
        </div> */}
    </div>
  );
}

export default Step2;
