import { useEffect } from 'react';
import { Hub } from 'aws-amplify/utils';
import { setHeaders } from './useSetHeaders';


const TokenFetcher = () => {

    // This works for when the user signs in or out
    Hub.listen('auth', ({ payload }) => {
        switch (payload.event) {
        case 'signedIn':
            console.log('user have been signedIn successfully.');
            setHeaders();
            break;
        case 'signedOut':
            console.log('user have been signedOut successfully.');
            break;
        }
    });

  return null; // Component does not render anything
};

export default TokenFetcher;
