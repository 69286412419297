import React, { useState } from 'react';
import '../global.css';

function CourtSelector({venuSetter}) {
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [thirdValue, setThirdValue] = useState('');

  const handleFirstChange = (event) => {
    setFirstValue(event.target.value);
    setSecondValue(''); // Reset second value when the first value changes
  };

  const handleSecondChange = (event) => {
    setSecondValue(event.target.value);
    if (firstValue === 'federal'){
        venuSetter(event.target.value);
    }
  };

  const handleThirdChange = (event) => {
    setThirdValue(event.target.value);
    venuSetter(secondValue + " " + event.target.value);
  };

  return (
    <div>
      <label className='label' htmlFor="venue">Jurisdiction / Venue:</label>
      <select className='select' id="firstSelector" value={firstValue} onChange={handleFirstChange}>
        <option value="">Select an option</option>
        <option value="federal">Federal Court</option>
        <option value="state">State Court</option>
      </select>

      {firstValue && (
        <div>
          <label className='label' htmlFor="secondSelector" style={{marginTop: '20px'}}>{firstValue === 'state' ? 'State' : 'District'}</label>
          <select className='select' id="secondSelector" value={secondValue} onChange={handleSecondChange}>
            <option value="">Select a {firstValue === 'state' ? 'State' : 'District'}</option>
            {firstValue === 'state' && (
              <>
                <option value="Alabama">Alabama</option>
                <option value="Alaska">Alaska</option>
                <option value="California">California</option>
              </>
            )}
            {firstValue === 'federal' && (
              <>
                <optgroup label="United States District Court for the">
                <option value="District of Alabama, Middle">District of Alabama, Middle</option>
                <option value="District of Alabama, Northern">District of Alabama, Northern</option>
                <option value="District of Alabama, Southern">District of Alabama, Southern</option>
                <option value="District of Alaska">District of Alaska</option>
                <option value="District of Arizona">District of Arizona</option>
                <option value="Eastern District of Arkansas">Eastern District of Arkansas</option>
                <option value="Western District of Arkansas">Western District of Arkansas</option>
                <option value="Central District of California">Central District of California</option>
                <option value="Eastern District of California">Eastern District of California</option>
                <option value="Northern District of California">Northern District of California</option>
                <option value="Southern District of California">Southern District of California</option>
                <option value="District of Colorado">District of Colorado</option>
                <option value="District of Connecticut">District of Connecticut</option>
                <option value="District of Delaware">District of Delaware</option>
                <option value="District of Columbia">District of Columbia</option>
                <option value="Northern District of Florida">Northern District of Florida</option>
                <option value="Middle District of Florida">Middle District of Florida</option>
                <option value="Southern District of Florida">Southern District of Florida</option>
                <option value="Northern District of Georgia">Northern District of Georgia</option>
                <option value="Middle District of Georgia">Middle District of Georgia</option>
                <option value="Southern District of Georgia">Southern District of Georgia</option>
                <option value="District of Guam">District of Guam</option>
                <option value="District of Hawaii">District of Hawaii</option>
                <option value="District of Idaho">District of Idaho</option>
                <option value="Central District of Illinois">Central District of Illinois</option>
                <option value="Northern District of Illinois">Northern District of Illinois</option>
                <option value="Southern District of Illinois">Southern District of Illinois</option>
                <option value="Northern District of Indiana">Northern District of Indiana</option>
                <option value="Southern District of Indiana">Southern District of Indiana</option>
                <option value="Northern District of Iowa">Northern District of Iowa</option>
                <option value="Southern District of Iowa">Southern District of Iowa</option>
                <option value="District of Kansas">District of Kansas</option>
                <option value="Eastern District of Kentucky">Eastern District of Kentucky</option>
                <option value="Western District of Kentucky">Western District of Kentucky</option>
                <option value="Eastern District of Louisiana">Eastern District of Louisiana</option>
                <option value="Middle District of Louisiana">Middle District of Louisiana</option>
                <option value="Western District of Louisiana">Western District of Louisiana</option>
                <option value="District of Maine">District of Maine</option>
                <option value="District of Maryland">District of Maryland</option>
                <option value="District of Massachusetts">District of Massachusetts</option>
                <option value="Eastern District of Michigan">Eastern District of Michigan</option>
                <option value="Western District of Michigan">Western District of Michigan</option>
                <option value="District of Minnesota">District of Minnesota</option>
                <option value="Northern District of Mississippi">Northern District of Mississippi</option>
                <option value="Southern District of Mississippi">Southern District of Mississippi</option>
                <option value="Eastern District of Missouri">Eastern District of Missouri</option>
                <option value="Western District of Missouri">Western District of Missouri</option>
                <option value="District of Montana">District of Montana</option>
                <option value="District of Nebraska">District of Nebraska</option>
                <option value="District of Nevada">District of Nevada</option>
                <option value="District of New Hampshire">District of New Hampshire</option>
                <option value="District of New Jersey">District of New Jersey</option>
                <option value="District of New Mexico">District of New Mexico</option>
                <option value="Eastern District of New York">Eastern District of New York</option>
                <option value="Northern District of New York">Northern District of New York</option>
                <option value="Southern District of New York">Southern District of New York</option>
                <option value="Western District of New York">Western District of New York</option>
                <option value="Eastern District of North Carolina">Eastern District of North Carolina</option>
                <option value="Middle District of North Carolina">Middle District of North Carolina</option>
                <option value="Western District of North Carolina">Western District of North Carolina</option>
                <option value="District of North Dakota">District of North Dakota</option>
                <option value="Northern District of Ohio">Northern District of Ohio</option>
                <option value="Southern District of Ohio">Southern District of Ohio</option>
                <option value="Eastern District of Oklahoma">Eastern District of Oklahoma</option>
                <option value="Northern District of Oklahoma">Northern District of Oklahoma</option>
                <option value="Western District of Oklahoma">Western District of Oklahoma</option>
                <option value="District of Oregon">District of Oregon</option>
                <option value="Eastern District of Pennsylvania">Eastern District of Pennsylvania</option>
                <option value="Middle District of Pennsylvania">Middle District of Pennsylvania</option>
                <option value="Western District of Pennsylvania">Western District of Pennsylvania</option>
                <option value="District of Puerto Rico">District of Puerto Rico</option>
                <option value="District of Rhode Island">District of Rhode Island</option>
                <option value="District of South Carolina">District of South Carolina</option>
                <option value="District of South Dakota">District of South Dakota</option>
                <option value="Eastern District of Tennessee">Eastern District of Tennessee</option>
                <option value="Middle District of Tennessee">Middle District of Tennessee</option>
                <option value="Western District of Tennessee">Western District of Tennessee</option>
                <option value="Eastern District of Texas">Eastern District of Texas</option>
                <option value="Northern District of Texas">Northern District of Texas</option>
                <option value="Southern District of Texas">Southern District of Texas</option>
                <option value="Western District of Texas">Western District of Texas</option>
                <option value="District of Utah">District of Utah</option>
                <option value="District of Vermont">District of Vermont</option>
                <option value="Eastern District of Virginia">Eastern District of Virginia</option>
                <option value="Western District of Virginia">Western District of Virginia</option>
                <option value="Eastern District of Washington">Eastern District of Washington</option>
                <option value="Western District of Washington">Western District of Washington</option>
                <option value="Northern District of West Virginia">Northern District of West Virginia</option>
                <option value="Southern District of West Virginia">Southern District of West Virginia</option>
                <option value="Eastern District of Wisconsin">Eastern District of Wisconsin</option>
                <option value="Western District of Wisconsin">Western District of Wisconsin</option>
                <option value="District of Wyoming">District of Wyoming</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="District of the Virgin Islands">District of the Virgin Islands</option>
                </optgroup>
              </>
            )}
          </select>
        </div>
      )}
        {secondValue && firstValue === 'state' && (
        <div>
          <label className='label' htmlFor="secondSelector" style={{marginTop: '20px'}}>District:</label>
          <select className='select' id="secondSelector" value={thirdValue} onChange={handleThirdChange}>
            <option value="">Select a District</option>
            {secondValue === 'Alabama' && (
              <>
                <option value="Autauga County Circuit Court">Autauga County Circuit Court</option>
                <option value="Baldwin County Circuit Court">Baldwin County Circuit Court</option>
                <option value="Barbour County Circuit Court">Barbour County Circuit Court</option>
                <option value="Bibb County Circuit Court">Bibb County Circuit Court</option>
                <option value="Blount County Circuit Court">Blount County Circuit Court</option>
                <option value="Bullock County Circuit Court">Bullock County Circuit Court</option>
                <option value="Butler County Circuit Court">Butler County Circuit Court</option>
                <option value="Calhoun County Circuit Court">Calhoun County Circuit Court</option>
                <option value="Chambers County Circuit Court">Chambers County Circuit Court</option>
                <option value="Cherokee County Circuit Court">Cherokee County Circuit Court</option>
                <option value="Chilton County Circuit Court">Chilton County Circuit Court</option>
                <option value="Choctaw County Circuit Court">Choctaw County Circuit Court</option>
                <option value="Clarke County Circuit Court">Clarke County Circuit Court</option>
                <option value="Clay County Circuit Court">Clay County Circuit Court</option>
                <option value="Cleburne County Circuit Court">Cleburne County Circuit Court</option>
                <option value="Coffee County Circuit Court">Coffee County Circuit Court</option>
                <option value="Colbert County Circuit Court">Colbert County Circuit Court</option>
                <option value="Conecuh County Circuit Court">Conecuh County Circuit Court</option>
                <option value="Coosa County Circuit Court">Coosa County Circuit Court</option>
                <option value="Covington County Circuit Court">Covington County Circuit Court</option>
                <option value="Crenshaw County Circuit Court">Crenshaw County Circuit Court</option>
                <option value="Cullman County Circuit Court">Cullman County Circuit Court</option>
                <option value="Dale County Circuit Court">Dale County Circuit Court</option>
                <option value="Dallas County Circuit Court">Dallas County Circuit Court</option>
                <option value="DeKalb County Circuit Court">DeKalb County Circuit Court</option>
                <option value="Elmore County Circuit Court">Elmore County Circuit Court</option>
                <option value="Escambia County Circuit Court">Escambia County Circuit Court</option>
                <option value="Etowah County Circuit Court">Etowah County Circuit Court</option>
                <option value="Fayette County Circuit Court">Fayette County Circuit Court</option>
                <option value="Franklin County Circuit Court">Franklin County Circuit Court</option>
                <option value="Geneva County Circuit Court">Geneva County Circuit Court</option>
                <option value="Greene County Circuit Court">Greene County Circuit Court</option>
                <option value="Hale County Circuit Court">Hale County Circuit Court</option>
                <option value="Henry County Circuit Court">Henry County Circuit Court</option>
                <option value="Houston County Circuit Court">Houston County Circuit Court</option>
                <option value="Jackson County Circuit Court">Jackson County Circuit Court</option>
                <option value="Jefferson County Circuit Court">Jefferson County Circuit Court</option>
                <option value="Lamar County Circuit Court">Lamar County Circuit Court</option>
                <option value="Lauderdale County Circuit Court">Lauderdale County Circuit Court</option>
                <option value="Lawrence County Circuit Court">Lawrence County Circuit Court</option>
                <option value="Lee County Circuit Court">Lee County Circuit Court</option>
                <option value="Limestone County Circuit Court">Limestone County Circuit Court</option>
                <option value="Lowndes County Circuit Court">Lowndes County Circuit Court</option>
                <option value="Macon County Circuit Court">Macon County Circuit Court</option>
                <option value="Madison County Circuit Court">Madison County Circuit Court</option>
                <option value="Marengo County Circuit Court">Marengo County Circuit Court</option>
                <option value="Marion County Circuit Court">Marion County Circuit Court</option>
                <option value="Marshall County Circuit Court">Marshall County Circuit Court</option>
                <option value="Mobile County Circuit Court">Mobile County Circuit Court</option>
                <option value="Monroe County Circuit Court">Monroe County Circuit Court</option>
                <option value="Montgomery County Circuit Court">Montgomery County Circuit Court</option>
                <option value="Morgan County Circuit Court">Morgan County Circuit Court</option>
                <option value="Perry County Circuit Court">Perry County Circuit Court</option>
                <option value="Pickens County Circuit Court">Pickens County Circuit Court</option>
                <option value="Pike County Circuit Court">Pike County Circuit Court</option>
                <option value="Randolph County Circuit Court">Randolph County Circuit Court</option>
                <option value="Russell County Circuit Court">Russell County Circuit Court</option>
                <option value="St. Clair County Circuit Court">St. Clair County Circuit Court</option>
                <option value="Shelby County Circuit Court">Shelby County Circuit Court</option>
                <option value="Sumter County Circuit Court">Sumter County Circuit Court</option>
                <option value="Talladega County Circuit Court">Talladega County Circuit Court</option>
                <option value="Tallapoosa County Circuit Court">Tallapoosa County Circuit Court</option>
                <option value="Tuscaloosa County Circuit Court">Tuscaloosa County Circuit Court</option>
                <option value="Walker County Circuit Court">Walker County Circuit Court</option>
                <option value="Washington County Circuit Court">Washington County Circuit Court</option>
                <option value="Wilcox County Circuit Court">Wilcox County Circuit Court</option>
                <option value="Winston County Circuit Court">Winston County Circuit Court</option>
              </>
            )}
            {secondValue === 'Alaska' && (
              <>
                <option value="First Judicial District (Juneau)">First Judicial District (Juneau)</option>
                <option value="Second Judicial District (Nome)">Second Judicial District (Nome)</option>
                <option value="Third Judicial District (Anchorage)">Third Judicial District (Anchorage)</option>
                <option value="Fourth Judicial District (Fairbanks)">Fourth Judicial District (Fairbanks)</option>
              </>
            )}
            {secondValue === 'California' && (
              <>
                <option value="Alameda County Superior Court">Alameda County Superior Court</option>
                <option value="Alpine County Superior Court">Alpine County Superior Court</option>
                <option value="Amador County Superior Court">Amador County Superior Court</option>
                <option value="Butte County Superior Court">Butte County Superior Court</option>
                <option value="Calaveras County Superior Court">Calaveras County Superior Court</option>
                <option value="Colusa County Superior Court">Colusa County Superior Court</option>
                <option value="Contra Costa County Superior Court">Contra Costa County Superior Court</option>
                <option value="Del Norte County Superior Court">Del Norte County Superior Court</option>
                <option value="El Dorado County Superior Court">El Dorado County Superior Court</option>
                <option value="Fresno County Superior Court">Fresno County Superior Court</option>
                <option value="Glenn County Superior Court">Glenn County Superior Court</option>
                <option value="Humboldt County Superior Court">Humboldt County Superior Court</option>
                <option value="Imperial County Superior Court">Imperial County Superior Court</option>
                <option value="Inyo County Superior Court">Inyo County Superior Court</option>
                <option value="Kern County Superior Court">Kern County Superior Court</option>
                <option value="Kings County Superior Court">Kings County Superior Court</option>
                <option value="Lake County Superior Court">Lake County Superior Court</option>
                <option value="Lassen County Superior Court">Lassen County Superior Court</option>
                <option value="Los Angeles County Superior Court">Los Angeles County Superior Court</option>
                <option value="Madera County Superior Court">Madera County Superior Court</option>
                <option value="Marin County Superior Court">Marin County Superior Court</option>
                <option value="Mariposa County Superior Court">Mariposa County Superior Court</option>
                <option value="Mendocino County Superior Court">Mendocino County Superior Court</option>
                <option value="Merced County Superior Court">Merced County Superior Court</option>
                <option value="Modoc County Superior Court">Modoc County Superior Court</option>
                <option value="Mono County Superior Court">Mono County Superior Court</option>
                <option value="Monterey County Superior Court">Monterey County Superior Court</option>
                <option value="Napa County Superior Court">Napa County Superior Court</option>
                <option value="Nevada County Superior Court">Nevada County Superior Court</option>
                <option value="Orange County Superior Court">Orange County Superior Court</option>
                <option value="Placer County Superior Court">Placer County Superior Court</option>
                <option value="Plumas County Superior Court">Plumas County Superior Court</option>
                <option value="Riverside County Superior Court">Riverside County Superior Court</option>
                <option value="Sacramento County Superior Court">Sacramento County Superior Court</option>
                <option value="San Benito County Superior Court">San Benito County Superior Court</option>
                <option value="San Bernardino County Superior Court">San Bernardino County Superior Court</option>
                <option value="San Diego County Superior Court">San Diego County Superior Court</option>
                <option value="San Francisco County Superior Court">San Francisco County Superior Court</option>
                <option value="San Joaquin County Superior Court">San Joaquin County Superior Court</option>
                <option value="San Luis Obispo County Superior Court">San Luis Obispo County Superior Court</option>
                <option value="San Mateo County Superior Court">San Mateo County Superior Court</option>
                <option value="Santa Barbara County Superior Court">Santa Barbara County Superior Court</option>
                <option value="Santa Clara County Superior Court">Santa Clara County Superior Court</option>
                <option value="Santa Cruz County Superior Court">Santa Cruz County Superior Court</option>
                <option value="Shasta County Superior Court">Shasta County Superior Court</option>
                <option value="Sierra County Superior Court">Sierra County Superior Court</option>
                <option value="Siskiyou County Superior Court">Siskiyou County Superior Court</option>
                <option value="Solano County Superior Court">Solano County Superior Court</option>
                <option value="Sonoma County Superior Court">Sonoma County Superior Court</option>
                <option value="Stanislaus County Superior Court">Stanislaus County Superior Court</option>
                <option value="Sutter County Superior Court">Sutter County Superior Court</option>
                <option value="Tehama County Superior Court">Tehama County Superior Court</option>
                <option value="Trinity County Superior Court">Trinity County Superior Court</option>
                <option value="Tulare County Superior Court">Tulare County Superior Court</option>
                <option value="Tuolumne County Superior Court">Tuolumne County Superior Court</option>
                <option value="Ventura County Superior Court">Ventura County Superior Court</option>
                <option value="Yolo County Superior Court">Yolo County Superior Court</option>
                <option value="Yuba County Superior Court">Yuba County Superior Court</option>
              </>
            )}
          </select>
        </div>
        )}
    </div>
  );
};

export default CourtSelector;
